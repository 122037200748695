import Vue from "vue";
import * as types from '../mutation-types'
import features, {subscriptionFeaturesDefaults} from "@/Features";
import {hasValue, trueIfUndef} from "@/utilities/utils";
import {configCatUser, getFeatureConfig} from "@/utilities/ConfigCat";
import {powerups} from "@/Constants";
import {StringUtils as $strings} from "@/utilities/Strings";
import {beginDownloadingAccount} from "@/store/actions";

function api() {
    return Vue.prototype.$api
}

const state = {
    accountsBeingLoaded : [],
    subscriptions : [],
    lastFetchedAt : false,
    overviews : [],
    currentAccountOverview: false,
    accountToLoadAfterHootsuiteAuthorization : false,
    accountToLoadAfterOauth:{
        sniply: false
    },
    accountIntegrationData: {},
    accountFeatureData: {},
    accountData: {},
    readyForLogin: false,
}

export const featuresForAccount = (subscriptions, codeFeatures, currentAccountFeatureData) => account => {
        let subFeatures = subscriptionFeaturesForAccount(subscriptions)(account);
        let globFeatures = codeFeatures || features;
        let featureToggles = currentAccountFeatureData;
        let keys = [...new Set([...Object.keys(globFeatures), ...Object.keys(subFeatures)])];
        let featureStatus = key => {
            let codeToggle = trueIfUndef(globFeatures[key]);
            let toggle = featureToggles[key];
            let toggledOn = hasValue(toggle) ? toggle : codeToggle;
            let hasSubscription = trueIfUndef(subFeatures[key]);
            return hasSubscription && toggledOn;
        }
        return keys.reduce((result, key) => {
            return {
            ...result,
            [key]: featureStatus(key)
        }
    }, {})
};

const currentSubscription = state => subscriptionForAccount(state.subscriptions, state.currentAccountOverview)
const subscriptionForAccount = (subscriptions, currentAccount) => {
    subscriptions = subscriptions.filter(s => currentAccount && s.account_id === currentAccount.id)
    if (subscriptions.length){
        return subscriptions[0];
    }
}

const currentPlans = state => (currentSubscription(state)||{}).plan_items||[];

const oneIfNotDef = x => !isNaN(parseInt(x)) ? parseInt(x) : 1

const planMetaValuesReducer = reduceValues => (combinedMetaData, plan) => (
    Object.keys(combinedMetaData).reduce((meta, key) => ({
        ...meta,
        [key]: reduceValues(combinedMetaData[key], plan[key], oneIfNotDef(plan.quantity))
    }), {}));

const reducedPlanMetaValues = (plans, reduceValues, initialMeta) =>
    plans.reduce(planMetaValuesReducer(reduceValues), initialMeta);

const subscriptionFeatures = state => _subscriptionFeatures(currentPlans(state));

const _subscriptionFeatures = plans => reducedPlanMetaValues(plans,
    (result, planVal) => (result || planVal) ? true : ((result===false)||(planVal===false)) ? false : undefined,
    subscriptionFeaturesDefaults
);

const subscriptionFeaturesForAccount = subscriptions => account => _subscriptionFeatures((subscriptionForAccount(subscriptions, account)||{}).plan_items||[])

const reducePlanLimits = plans => reducedPlanMetaValues(plans, (result, planVal, quantity) =>
    result === 'unlimited' || planVal === 'unlimited' ? 'unlimited' : Number(result) + Number(planVal) * quantity,
    { topics: 0, collections: 0, queries: 0, users: 0, contentPolicyRules:0, gallery_sites: 0, shutterstock_images: 0, proofpoint_configs: 0, contentTemplateCount: 0 }
)

const subscriptionLimits = state => {
    let plans = currentPlans(state);
    return plans.length ? reducePlanLimits(plans) :{ topics: 1, collections: 0, queries: 15, users: 1, contentPolicyRules:0 }
};


let subscriptionDataForAccount = function (allSubscriptions, acc) {
    let subscriptions = allSubscriptions.filter(sub => sub.account_id === acc.id);
    let planItems = subscriptions.flatMap(x => x.plan_items);
    let features = reducedPlanMetaValues(planItems,
        (result, planVal) => !!(result || planVal),
        {rss: false, crossAccount: false}
    );

    let limits = planItems.length ? reducePlanLimits(planItems) : {topics: 1, collections: 0, queries: 15, users: 1, contentPolicyRules:0}
    return {
        accountId: acc.id,
        subscriptions, planItems, features, limits
    }
};

const expect404s = e => {
    if (e.response.status !== 404)
        throw e;
};

const fetchAccountSummary = async(accountUrl, cancelToken) => { //deprecated in favor of /overview, which has all info and more
    console.log('* fetchAccountSummary accountUrl', accountUrl)
    return await api().limit.get(accountUrl, {cancelToken});
};

const fetchAllAccountsForUser = async() => {
    return await api().limit.get('/accounts');
};

const fetchAccountUsers = async(accountId, cancelToken) => {
    return await api().limit.get(`/accounts/${accountId}/users`, {cancelToken, ignoreCache: true});
};

const fetchAccountSubscription = async(accountId) => {
    try {
        return await api().limit.get(`/accounts/${accountId}/subscription`);
    } catch(e) {
        expect404s(e)
    }
};

const fetchAccountIntegrations = async() => {
    return await api().limit.get('/integrations?meta=true');
};

const fetchAccountPowerupSettings = async(accountId, powerup, cancelToken) => {
    //console.log('* fetchAccountPowerupSettings accountId, powerup', accountId, powerup)
    try {
        return await api().limit.get(`/accounts/${accountId}/${powerup}-settings`, {cancelToken, ignoreCache: true});
    } catch (e) {
        console.error(`fetchAccountPowerupSettings error: ${e}`)
    }
};

const fetchAccountNotes = async(accountId, cancelToken) => {
    return await api().limit.get(`/accounts/${accountId}/notes`, {cancelToken, ignoreCache: true});
};

const fetchAccountTopics = async(accountId, cancelToken) => {
    return await api().limit.get(`/accounts/${accountId}/active-topics`, {cancelToken, ignoreCache: true});
};

const fetchAccountFolders = async(accountId, cancelToken) => {
    return await api().limit.get(`/accounts/${accountId}/folders`, {cancelToken, ignoreCache: true});
};

const fetchAccountCollections = async(accountId, cancelToken) => {
    return await api().limit.get(`/accounts/${accountId}/collections`, {cancelToken, ignoreCache: true});
};

const fetchAccountOverview = async(accountId, cancelToken) => {
    return await api().get(`/accounts/${accountId}/overview`, {cancelToken, ignoreCache: true});
};

const getCurrentAccount = (summaries, user) => {
    console.log('* accounts.js getCurrentAccount summaries', summaries)
    let userPrimary = user && user.default_account;
    let deviceDefault = (JSON.parse(localStorage.getItem('defaultAccounts') || '{}') || {})[user && user.id];
    let defaultAccount = deviceDefault || userPrimary;
    const summariesDerivedDefaultAccount = summaries.filter(s => s.id === defaultAccount.slice(-36))[-1];
    console.log('* accounts.js getCurrentAccount summariesDerivedDefaultAccount', summariesDerivedDefaultAccount)

    console.log('* accounts.js getCurrentAccount localStorage.getItem(\'accountId\')', localStorage.getItem('accountId')) // null for creating new account via signup
    const recentAccountId = localStorage.getItem('accountId') || {};
    const summariesDerivedRecentAccount = summaries.filter(s => s.id === recentAccountId)[0];
    console.log('* accounts.js getCurrentAccount summariesDerivedRecentAccount', summariesDerivedRecentAccount)

    if(summariesDerivedRecentAccount) {
        console.log('* accounts.js getCurrentAccount if summariesDerivedRecentAccount', summariesDerivedRecentAccount)
        return summariesDerivedRecentAccount;
    }
    else if(summariesDerivedDefaultAccount) {
        console.log('* accounts.js getCurrentAccount if summariesDerivedDefaultAccount', summariesDerivedDefaultAccount)
        return summariesDerivedDefaultAccount;
    } else {
        console.warn('getCurrentAccount: Error confirming default user account matching current account, default to latest account');
        return summaries.at(-1);
    }
};


const getters = {
    shareProviderForCurrentAccount: (state, getters, rootState, rootGetters) => {
        let currentAccount = getters.currentAccountOverview;
        let shareProviderName = currentAccount && currentAccount.share_provider;
        let shareProviderOption = rootGetters.shareProviders.find(sp => sp.name === shareProviderName);
        if (shareProviderOption) return shareProviderName;


        shareProviderName = rootGetters.vendor;
        shareProviderOption = rootGetters.shareProviders.find(sp => sp.name === shareProviderName);
        if (shareProviderOption) return shareProviderName;

        return 'buffer'
    },
    currentShareProviderOptions: state => {
        return state.currentAccountOverview
            && state.currentAccountOverview.id
            && state.accountIntegrationData[state.currentAccountOverview.id];
    },
    currentSubscription, currentPlans, subscriptionFeatures, subscriptionLimits, subscriptionFeaturesForAccount,
    currentPackage: (s, g) => g.currentPlans.find(p => p.plan_key === g.currentSubscription.plan_key),
    currentAccountFeatureData: (s) => s.accountFeatureData[s.currentAccountOverview && s.currentAccountOverview.id]||{},
    accountFeatures: (state, getters, rootState, rootGetters) => {
        return featuresForAccount(state.subscriptions, rootGetters.features, getters.currentAccountFeatureData)(getters.currentAccountOverview)
    },
    allAccounts : state => {
        return state.overviews.filter(x => x)
    },
    accountsBeingLoaded : state => state.accountsBeingLoaded,
    subscriptions : state => state.subscriptions,
    accountsLastFetchedAt : state => state.lastFetchedAt,
    subscriptionPlan : state => {
        let subscriptions = state.subscriptions.filter(s => state.currentAccountOverview && s.account_id === state.currentAccountOverview.id)
        if (subscriptions.length){
            return subscriptions[0].package;
        }
    },
    subscriptionDataByAccount : state =>  {
        return state.overviews.map(acc => {
            return subscriptionDataForAccount(state.subscriptions, acc);
        })
    },
    accountsWithCrossAccount: (state, getters) => { // syndication
        return getters.subscriptionDataByAccount.filter(x => x.features.crossAccount).map(x => x.accountId)
    },
    accountOverviews : state => state.overviews,
    currentAccountOverview: state => state.currentAccountOverview,
    currentAccount: (state, getters) => getters.currentAccountOverview,
    account: (state, getters) => getters.currentAccountOverview,
    accountToLoadAfterHootsuiteAuthorization: state => state.accountToLoadAfterHootsuiteAuthorization,
    accountToLoadAfterOauth: state => state.accountToLoadAfterOauth,
    currentAccountData: state => state.accountData,
    currentAccountDataOpenai: state => state.accountData && state.accountData.openai,
    readyForLogin: state => state.readyForLogin,
}


const actions = {
    setAccountToLoadAfterHootsuiteAuthorization : ({commit}, {account}) => {
        commit("SET_ACCOUNT_TO_LOAD_AFTER_HOOTSUITE_AUTHORIZATION", {account});
    },
    setAccountToLoadAfterOAuth : ({commit}, {account, powerup}) => {
        commit("SET_ACCOUNT_TO_LOAD_AFTER_OAUTH", {account, powerup});
    },
    setFeatureData: ({commit}, {accountId, featureData}) => {
        commit('SET_FEATURE_DATA', {accountId, featureData});
    },
    setCurrentAccountOverview({commit, getters}, {overview}) {
        console.log('* accounts.js setCurrentAccountOverview', overview)
        commit('SET_CURRENT_ACCOUNT_OVERVIEW', {overview});
    },
    setOverviewInOverviews({commit, getters}, {overview}) {
        console.log('* accounts.js setOverviewInOverviews', overview)
        commit('SET_ACCOUNT_OVERVIEW_IN_OVERVIEWS', {overview});
    },
    setReadyForLogin({commit}, {status}) {
        commit('READY_FOR_LOGIN', {status});
    },
    async changeDataForAccount({commit, dispatch, getters}, {overview}) {
        await dispatch('setCurrentAccountOverview', { overview: overview });
        await dispatch('setOverviewInOverviews', { overview: overview });
    },
    async changeAccount({commit, dispatch, getters}, {overview}){
        if(!overview) {
            console.log('* changeAccount new account new user, need to initialize')
            await dispatch('initialize');
            return;
        }
        await dispatch('setCurrentAccountOverview', { overview: overview });
        console.log('* accounts.js changeAccount getters.currentAccountOverview', getters.currentAccountOverview);

        const hasCollectionsInAccount = getters.currentAccountOverview.collections.length > 0;
        const hasTopicsInAccount = getters.currentAccountOverview.topics.length > 0;
        if(hasCollectionsInAccount) {
            const collectionToSet = getters.currentAccountOverview.collections[0];
            await dispatch('setCurrentCollection', { collection : collectionToSet });
        }
        if(!hasCollectionsInAccount && hasTopicsInAccount) {
            const topicToSet = getters.currentAccountOverview.topics[0];
            await dispatch('setCurrentTopic', { topic : topicToSet });
        }
        if(!hasCollectionsInAccount && !hasTopicsInAccount) {
            //load a welcome create your first topic screen
            await dispatch('setCurrentTopic', { topic : false });
        }
    },
    async setupNewAccount({dispatch, getters}, {overview}) {
        console.log('* accounts.js setupNewAccount')
        await dispatch('setCurrentAccountOverview', { overview: overview });
        await dispatch('addAccountOverview', { overview: overview });

        await dispatch('loadAccountDependenciesViaOverview', { overview: getters.currentAccountOverview });
        //do we need dispatch('setCurrentTopic', {topic: false});? should be handled by loadAccountDependenciesViaOverview
    },
    async loadAccountData({commit, getters}) {
        if(getters.currentAccountOverview && getters.currentAccountOverview.id) {
            let accountData = ['openai'];
            let fetchData = async x => ({key: x, response: await api().get(`/accounts/${getters.currentAccountOverview.id}/data/${x}`)});
            let results = await Promise.all(accountData.map(fetchData));
            results = results.map(({key, response}) =>
                response.status === 200 && response.data ? {key, response} : {key, response: {}}
            )
            results.forEach(({key, response: {data}}) => {
                commit("SET_ACCOUNT_DATA", {key, data});
            });
        }
    },
    async loadAccountSubscription({ dispatch }, account) {
        console.log('* loadAccountSubscription account', account)
        const response =  await fetchAccountSubscription(account.id);
        if(response && response.data) {
            const subscription = response.data;
            await dispatch('addSubscriptionToAccount', { subscription: subscription, account: account });
            return subscription;
        }
    },
    async loadAccountFeatureData({ dispatch, getters }, account ) {
        let configUser = configCatUser(getters.currentUser, account);
        const featureData = await getFeatureConfig(configUser);
        await dispatch('setFeatureData', { accountId: account.id, featureData: featureData });
    },
    async loadSubscriptionAndFeatureData({ dispatch }, { account }) {
        await dispatch('loadAccountSubscription', account);
        await dispatch('loadAccountFeatureData', account);
    },
    async loadAccountIntegrations({dispatch}) {
        const response = await fetchAccountIntegrations();
        const integrations = response.data;
        await dispatch('setIntegrationMetaAll', { integrations: integrations });
        return integrations;
    },
    async loadAccountPowerupSettings({dispatch}, {account, powerup}){
        if(!account.id || !powerup) {
            console.error(`No account id (${account.id}) or powerup (${powerup}) required to fetch powerup settings`);
            return;
        }
        const cancelToken = await dispatch('getNewTokenAndCancelPreviousRequests', {category: `loadAccountPowerupSettings${account.id}`});
        const response = await fetchAccountPowerupSettings(account.id, powerup, cancelToken);
        if(response && response.data) {
            const data = response.data;
            const token = data.access_token;
            await dispatch('setPowerupDataForAccount', {data : token ? {token, ...data} : false, account, powerup});
        }
    },
    async loadAccountIntegrationSettings({ dispatch },{ account, integrations }) {
        const integrationList = [...integrations.map(i => i.name), ...powerups];
        let loadPowerup = async(powerup) => await dispatch('loadAccountPowerupSettings', { account, powerup });
        return await Promise.all(integrationList.map(loadPowerup));
    },
    async loadAccountNotes({ dispatch, getters }, { account }) {
        dispatch('beginDownloadingNotes');
        const cancelToken = await dispatch('getNewTokenAndCancelPreviousRequests', {category: `loadAccountNotes${account.id}`});

        await fetchAccountNotes(account.id, cancelToken)
            .then(async(response) => {
                const notes = response.data;
                return await dispatch('handleUrls', {
                    urls: notes,
                    objectType: 'note',
                    accountId: account.id,
                    objects: getters.notes
                });
            })
            .finally(() => {
                setTimeout(() => dispatch('completeDownloadingNotes'), 2000);
            });
    },
    async handleUrls({dispatch}, {urls, objectType, accountId, objects})  {
        console.log(' ***** urls', urls)
        const ids = urls.map(url => url.substr(url.lastIndexOf('/') + 1));
        const itemsToRemove = objects
            .filter(t => t)
            .filter(t => ! t.account || (t.account.includes(accountId) && ids.indexOf(t.id) === -1));
        const itemsToUpdate = ids.filter(id => itemsToRemove.indexOf(id) === -1);

        if (objectType === 'note'){
            console.log('* handleUrls note')
            itemsToUpdate.forEach((async(itemId) => {
                let url = urls.find(item => item.includes(itemId));
                await dispatch('fetchItem', {
                    url: url,
                    type: objectType,
                    action: 'add' + $strings.capitalize(objectType),
                    accountId: accountId
                });
            }))
        }
        itemsToRemove.forEach(item => {
            dispatch('removeItem', {
                item: item,
                type: objectType,
                action: 'remove' + $strings.capitalize(objectType)
            });
        })
    },
    async fetchItem({ dispatch, getters }, { url, type, action, accountId })  {
        const cancelToken = await dispatch('getNewTokenAndCancelPreviousRequests', {category: `fetchItem${accountId}`})

        return api().limit.get(url, {cancelToken})
            .then(response => {
                const object = {};
                object[$strings.plural(type, 1)] = {...response.data, account : accountId};
                dispatch(action, object);
                if (type === 'user'){
                    dispatch('setRoleForUser', object );
                }
                if (type === 'note'){
                    window.Events.$emit('CheckForNoteSuggestions');
                }
                if (type === 'topic' && accountId === getters.currentAccountOverview.id){
                    console.log('* fetchItem topic')
                    const topics = this.primaryAccountTopics
                    topics.splice(topics.indexOf(url), 1);
                    this.primaryAccountTopics = topics;
                }

            }).catch((e) => {
                console.error(`in catch for fetchItem error: ${e}`)
            })
    },
    async removeItem({ dispatch }, {item, type, action })  {
        const object = {}
        object[$strings.plural(type, 1)] = item;
        dispatch(action, object);
    },
    async loadAccountDependenciesViaOverview({ dispatch, getters }, { overview }){
        //console.log('* loadAccountDependenciesViaOverview')
        console.log('* loadAccountDependenciesViaOverview overview', overview)
        console.log('* loadAccountDependenciesViaOverview getters.currentAccountOverview', getters.currentAccountOverview)
        //INTEGRATIONS
        await dispatch('loadAccountIntegrations'); // sets getters.apisMeta
        await dispatch('loadAccountIntegrationSettings', {
            account: overview,
            integrations: Object.keys(getters.apisMeta).map((key) => getters.apisMeta[key])
        });
        //console.log('* loadAccountDependenciesViaOverview after setting integrations')

        //TOPICS
        if(overview.topics && overview.topics.length > 0) {
            //console.log('* loadAccountDependenciesViaOverview overview.topics BEFORE', overview.topics)
            await dispatch('addTopics', {topics: overview.topics.map(o => { // should
                    return {...o, account: overview.id, incomplete: true}
                })});
            //console.log('* loadAccountDependenciesViaOverview overview.topics AFTER', overview.topics)
        }

        console.log('* loadAccountDependenciesViaOverview getters.topics', getters.topics)
        // const accountTopics = overview.topics.filter(t => t.account.includes(overview.id));
        // console.log('* loadAccountDependenciesViaOverview accountTopics', accountTopics)
        //console.log('* loadAccountDependenciesViaOverview getters.currentTopic', getters.currentTopic)

        const currentAccountOverviewHasTopics = getters.currentAccountOverview?.topics?.length > 0;
        const currentTopicNotPartOfOverviewTopics = !getters.currentAccountOverview?.topics?.map(t => t.id).includes(getters.currentTopic?.id);
        if((!getters.currentTopic || currentTopicNotPartOfOverviewTopics && currentAccountOverviewHasTopics)) {
            console.log('* loadAccountDependenciesViaOverview in if block for topics')
            if(getters.currentAccountOverview.id === overview.id) {
                if(overview.topics?.length > 0) { // if current overview has topic to load
                    const topic = overview.topics[0];
                    //console.log('* topic', topic)
                    await dispatch('setCurrentTopic', { topic: topic });
                }
            }
        }

        //COLLECTIONS
        console.log('* accounts.js loadAccountDependenciesViaOverview overview.collections', overview.collections)

        if(overview.collections && overview.collections.length > 0) {
            await dispatch('addCollections', { collections : overview.collections.map(o => {
                    return {...o, account: overview.id, incomplete: true }
                })});
        }

        const currentAccountOverviewHasCollections = getters.currentAccountOverview?.collections?.length > 0;
        console.log('* accounts.js loadAccountDependenciesViaOverview currentAccountOverviewHasCollections', currentAccountOverviewHasCollections)
        const currentCollectionNotPartOfOverviewCollections = !getters.currentAccountOverview?.collections?.map(c => c.id).includes(getters.currentCollection?.id); //
        console.log('* accounts.js loadAccountDependenciesViaOverview currentCollectionNotPartOfOverviewCollections', currentCollectionNotPartOfOverviewCollections) //FIXME: why false when changing account
        console.log('* accounts.js loadAccountDependenciesViaOverview getters.currentCollection', getters.currentCollection)
        console.log('* accounts.js loadAccountDependenciesViaOverview  !getters.currentCollection || currentCollectionNotPartOfOverviewCollections && currentAccountOverviewHasCollections', (!getters.currentCollection || currentCollectionNotPartOfOverviewCollections && currentAccountOverviewHasCollections))
        if((!getters.currentCollection || currentCollectionNotPartOfOverviewCollections && currentAccountOverviewHasCollections)) {
            console.log('* loadAccountDependenciesViaOverview in if block for collections') //should not be here on reload for same account
            if(getters.currentAccountOverview.id === overview.id) { // loading the currentAccount, not another account overview's dependencies
                if(overview.collections?.length > 0) { // if current overview has collection to load
                    const collection = overview.collections[0];
                    console.log('* collection', collection)
                    await dispatch('setCurrentCollection', { collection: collection });
                }
            }
        }

        //FOLDERS
        console.log('* accounts.js loadAccountDependenciesViaOverview overview.folders', overview.folders)
        await dispatch('addFolders', { folders : overview.folders.map(o => {
                return {...o, account: overview.id, incomplete: true }
        })});

        //USERS
        overview.users.forEach( u => {
            let user = {...u, account: overview.id, incomplete: true };
            console.log('* loadAccountDependenciesViaOverview user', user)
            dispatch('addUser', { user });
            dispatch('addTeamMemberToAccount', { user: user, account: overview });
        });

        dispatch('completeDownloadingAccount', { account: overview });

    },
    async loadAccountsViaOverviews({dispatch, getters}, {specificAccountId}) {
        console.log('* loadAccountsViaOverviews');
        //console.log('* getters.currentAccountOverview BEGINNING', getters.currentAccountOverview)
        let isLogin = false;
        if(!getters.currentAccountOverview) {
            console.log('* no current account overview set, should be login scenario')
            isLogin = true;
        }

        const response = await fetchAllAccountsForUser();
        const urls = response.data;
        await Promise.all([ // mark beginning of downloading for accounts
            ...urls.map(a => {
                dispatch('beginDownloadingAccount', { url: a } );
            })
        ]);
        const accountIds = urls.map(i => i.slice(-36))
        //console.log('* accountIds', accountIds)


        let overviews = (await Promise.all(accountIds.map(async(id) => {
            try {
                let cancelToken = await dispatch('getNewTokenAndCancelPreviousRequests', {category: `fetchAccountOverview${id}`});
                const accountOverview = await fetchAccountOverview(id, cancelToken).then(response => response.data);
                console.log('* ACCOUNT OVERVIEW', accountOverview)
                await dispatch('addAccountOverview', {overview: accountOverview});
                return accountOverview;
            } catch (error) {
                console.error(error)
                return null;
            }
        }))).filter(a => a);
        console.log('** overviews', overviews)
        console.log('* getters.allAccounts', getters.allAccounts)

        console.log('* getters.currentAccountOverview BEFORE', getters.currentAccountOverview)

        let currentAccount = isLogin ? getCurrentAccount(overviews, getters.currentUser) : getters.currentAccountOverview; // on login
        if(!currentAccount) await dispatch('completeDownloadingAccount', {account : currentAccount})

        console.log('* currentAccount', currentAccount)
        const overviewToShow = specificAccountId
            ? overviews.filter(o => o.id === specificAccountId)[0] || currentAccount
            : currentAccount;
        console.log('* overviewToShow', overviewToShow);
        await dispatch('setCurrentAccountOverview', { overview: overviewToShow }); // truly an overview
        console.log('* getters.currentAccountOverview AFTER', getters.currentAccountOverview)

        let otherAccounts = overviews.filter(s => s.id !== currentAccount.id);
        console.log('*** otherAccounts', otherAccounts)

        // load the bare minimum for the current account
        await dispatch('loadSubscriptionAndFeatureData', { account: getters.currentAccountOverview });
        //console.log('* after loadSubscriptionAndFeatureData')
        console.log('*** getters.accountsWithCrossAccount', getters.accountsWithCrossAccount)

        const initialLoadOfCurrentAccountComplete = dispatch('loadAccountDependenciesViaOverview', { overview: getters.currentAccountOverview });
        const loadedFlagsForOtherAccounts = otherAccounts.map(a => dispatch('loadSubscriptionAndFeatureData', { account: a }));
        const readyToLoadOtherAccounts = Promise.all([initialLoadOfCurrentAccountComplete, ...loadedFlagsForOtherAccounts,]);

        await initialLoadOfCurrentAccountComplete;
        await dispatch('setReadyForLogin', {status: true});
        await readyToLoadOtherAccounts;


        console.log('* other account summaries', otherAccounts); // syndication only impacts how OTHER account get loaded, and we only load topics and collections for accounts that have it

        // const accountHasSyndication = a => getters.accountsWithCrossAccount.some(a => a.accountId === a.id);
        //let accountsWithSyndication = otherAccounts.filter(accountHasSyndication) // pull from the subscription data in store, add in after
        //console.log('* accountsWithSyndication', accountsWithSyndication)
        console.log('*** getters.accountsWithCrossAccount', getters.accountsWithCrossAccount)

        await Promise.all([ // stuff that is least relevant for page load
            dispatch('loadAccountNotes', { account: getters.currentAccountOverview }),
            ...otherAccounts.map(a => {
                dispatch('loadAccountDependenciesViaOverview', { overview: a } )
            })
        ]);

    },
    async initialize({dispatch, getters}) { //full load
        if(!getters.currentUser){
            console.error('* no user available to initialize accounts');
            return;
        }
        await dispatch('loadAccountsViaOverviews', {specificAccountId: null});
    },
    async initializeForSpecificAccountId({dispatch, getters}, {specificAccountId}) { //full load
        if(!getters.currentUser){
            console.error('* no user available to initialize accounts');
            return;
        }
        console.log('* initializeForSpecificAccountId loadAccountsViaOverviews for:', specificAccountId)
        await dispatch('loadAccountsViaOverviews', {specificAccountId: specificAccountId});
    },
    async preload() {
        //loading a limited set
    },

}


const mutations = {
    SET_FEATURE_DATA: (state, {accountId, featureData}) => {
        state.accountFeatureData[accountId] = featureData;
    },
    SET_SHARE_PROVIDER: (state, {shareProvider, account}) => {
        state.overviews.find(a => a.id === account.id).share_provider = shareProvider;
    },
    SET_ACCOUNT_TO_LOAD_AFTER_HOOTSUITE_AUTHORIZATION : (state, {account}) => {
        state.accountToLoadAfterHootsuiteAuthorization = account;
    },
    SET_ACCOUNT_TO_LOAD_AFTER_OAUTH : (state, {account, powerup}) => {
        state.accountToLoadAfterOauth[powerup] = account;
    },
    SET_ACCOUNT_DATA: (state, {key, data}) => {
        state.accountData = {...state.accountData, [key]: data}
    },
    SET_CURRENT_ACCOUNT_OVERVIEW: (state, {overview}) => {
        console.log('* accounts.js SET_CURRENT_ACCOUNT_OVERVIEW overview', overview)
        state.currentAccountOverview = overview;
        localStorage.setItem('accountId', overview.id)
    },
    SET_ACCOUNT_OVERVIEW_IN_OVERVIEWS: (state, {overview}) => {
        const indexOverviewToReplace = state.overviews.findIndex(o => o.id === overview.id);
        //console.log('* SET_CURRENT_ACCOUNT_OVERVIEW indexOverviewToReplace', indexOverviewToReplace)
        state.overviews.splice(indexOverviewToReplace, 1, overview);
        //console.log('* accounts.js SET_ACCOUNT_OVERVIEW_IN_OVERVIEWS  state.overviews', state.overviews)
    },
    READY_FOR_LOGIN: (state, {status}) => {
        state.readyForLogin = status;
    },
    [types.ADD_ACCOUNT_OVERVIEW](state, {overview}){
        console.log('* accounts.js types.ADD_ACCOUNT_OVERVIEW overview', overview)

        if (!overview) return;
        //console.log('* accounts.js types.ADD_ACCOUNT_OVERVIEW state.overviews', state.overviews)
        let existing = state.overviews.find(a => a && overview.id === a.id);
        console.log('* accounts.js types.ADD_ACCOUNT_OVERVIEW existing', existing)
        if (!existing){
            console.log('* accounts.js types.ADD_ACCOUNT_OVERVIEW !existing')
            state.overviews.push(overview)
        } else if(existing.updated <= overview.updated || !existing.updated) { //TODO: make sure updated field is updated correctly so it can just be <
            console.log('* accounts.js types.ADD_ACCOUNT_OVERVIEW else if')
            let index = state.overviews.indexOf(existing);
            //console.log('* accounts.js types.ADD_ACCOUNT_OVERVIEW index', index)
            state.overviews.splice(index, 1, overview);
        }
        // console.log('* accounts.js .ADD_ACCOUNT_OVERVIEW state.overviews', state.overviews)

        // Ensure state.currentAccountOverview is updated if it's the same as the modified overview
        if (state.currentAccountOverview && state.currentAccountOverview.id === overview.id) {
            state.currentAccountOverview = overview;
        }
    },
    [types.SET_LATEST_ACCOUNTS_FETCHED_AT](state, {date}){
        state.lastFetchedAt = date;
    },
    [types.ADD_SUBSCRIPTION_TO_ACCOUNT](state, {account, subscription}){
        //console.log('* accounts.js types.ADD_SUBSCRIPTION_TO_ACCOUNT')
        let sub = { ...subscription, account_id: account.id }
        //console.log('* accounts.js types.ADD_SUBSCRIPTION_TO_ACCOUNT sub', sub)
        let existing = state.subscriptions.find(s => sub.account_id === s.account_id)
        //console.log('* accounts.js types.ADD_SUBSCRIPTION_TO_ACCOUNT existing', existing)
        if (typeof sub.plan_items === "string") sub.plan_items = JSON.parse(sub.plan_items);

        if ( !existing){
            state.subscriptions.push(sub)
        } else {
            //console.log('* accounts.js types.ADD_SUBSCRIPTION_TO_ACCOUNT in else')
            let index = state.subscriptions.indexOf(existing)
            //console.log('*  accounts.js types.ADD_SUBSCRIPTION_TO_ACCOUNT index', index)
            state.subscriptions.splice(index, 1, sub)
        }
    },
    [types.USER_DID_LOG_OUT](state) {
        console.log('* USER_DID_LOG_OUT account.js')
        state.currentAccountOverview = false;
        state.overviews = [];
        state.accountsBeingLoaded = [];
        state.subscriptions = [];
        state.lastFetchedAt = false;
        state.signupPath = '';
        state.readyForLogin = false;
        state.accountData = {};
        state.accountFeatureData = {};
        state.accountIntegrationData = {};
    },
    [types.BEGIN_DOWNLOADING_ACCOUNT](state, {url}){
        if ( state.accountsBeingLoaded.indexOf(url) === -1 )
            state.accountsBeingLoaded.push(url)
    },
    [types.COMPLETE_DOWNLOADING_ACCOUNT](state, {account}){
        if (!account) return;
        state.accountsBeingLoaded = state.accountsBeingLoaded.filter(url => {
            return ! url.includes(account.id)
        })

        if ( ! state.overviews.find(a => a.id === account.id))
            state.overviews.push(account);
    },
    [types.UPDATE_ACCOUNT](state, {account}){
        let existing = state.overviews.find(a => account.id === a.id);
        if (existing) {
            let index = state.overviews.indexOf(existing);
            state.overviews.splice(index, 1, account);

        }
    },
    [types.ADD_FOLDER_TO_FOLDER](state, { folder, account }) {

        const accountToUpdate = state.overviews.find(a => a.id === account.id);

        if (accountToUpdate) {
            let index = state.overviews.indexOf(accountToUpdate)
            accountToUpdate.folders.push(folder)
            state.overviews.splice(index, 1, accountToUpdate)
        }

    },
    [types.ADD_TEAM_MEMBER_TO_ACCOUNT](state, {user, account}){
        let existingAccount = state.overviews.find(a => a.id === account.id);
        if (existingAccount){
            let index = state.overviews.indexOf(existingAccount)
            if (!account.users.find(u => u.id === user.id)) {
                let users = [...account.users, user.id]
                let updatedAccount = { ...account, users }
                state.overviews.splice(index, 1, updatedAccount)
            }
        }
    },
    [types.DELETE_ACCOUNT](state, {account}){
        let existingAccount = state.overviews.find(a => a.id === account.id);
        console.log('* accounts.js types.DELETE_ACCOUNT existingAccount', existingAccount)
        if (existingAccount) {
            let index = state.overviews.indexOf(existingAccount);
            state.overviews.splice(index, 1);
        }
        console.log('* accounts.js types.DELETE_ACCOUNT state.currentAccountOverview', state.currentAccountOverview)

        if (state.currentAccountOverview.id === existingAccount.id){
            state.currentAccountOverview = state.overviews[0];
        }
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
